<template>
  <div>
    <Homepage />
  </div>
</template>
<script>
import Homepage from "@/components/Homepage";
export default {
  components: {
    Homepage,
  },
  created() {
    if (localStorage.getItem("usersarabantrapiangtia") === null) {
      this.$router.push("/manageform1").catch(() => {});
    }
  },
};
</script>
